@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.woff?kng4ym') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-banner:before {
  content: "\e901";
}
.icon-opd:before {
  content: "\e902";
}
.icon-attach:before {
  content: "\e900";
}
.icon-constr:before {
  content: "\e90b";
}
.icon-count:before {
  content: "\e90c";
}
.icon-draw:before {
  content: "\e90d";
}
.icon-experium:before {
  content: "\e90e";
}
.icon-jobot:before {
  content: "\e90f";
}
.icon-landing:before {
  content: "\e910";
}
.icon-ad:before {
  content: "\e915";
}
.icon-add:before {
  content: "\e916";
}
.icon-anketa:before {
  content: "\e917";
}
.icon-check:before {
  content: "\e918";
}
.icon-domain:before {
  content: "\e919";
}
.icon-response:before {
  content: "\e91a";
}
.icon-widget-check:before {
  content: "\e91b";
}
.icon-widget:before {
  content: "\e91c";
}
